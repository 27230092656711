import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav_manager";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const [agentList,setAgentList]=useState([])
  const [agentListArray,setAgentListArray]=useState([])
  const [search,setSearch]=useState()
  
  useEffect(() => {
    const a=localStorage.getItem("Manager");
    if(a==null)
    {
      window.location.replace("/login")
    }else{

      TeamList("Agent",JSON.parse(a)[0].team)
    }


  
  
  }, []);




  const TeamList = async (typ,id)=> {
    const data = new FormData();
        data.append('team_id',id);
        data.append('type',typ);
            axios({
              method: 'post',
              url: '/api/manager/team_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
    
             

                    setAgentList(s.data)
                    setAgentListArray(s.data)
                
    
           console.log("List of team",s.data)
    
    
    
                 
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const searchx = (t) => {
            setSearch(t);
        
            const data = [...agentList];
            const newData = data.filter((item) => {
              const itemData = `${item.name.toUpperCase()} ${item.username.toUpperCase()}`;
              const textData = t.toUpperCase();
        
              return itemData.indexOf(textData) > -1;
            });

            if (t === "") {
              setAgentList(agentListArray);
            } else {
              setAgentList(newData);
            }
          };

  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
         
          
        

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>All Team </h4>
   
   
   </div>
   <div className="card-body">
 <div>
 <div id="data-table_wrapper" className="dataTables_filter">
  <label>
    Search :
    <input
      type="search"
      className="form-control form-control-sm"
      placeholder="Name or Username"
      value={search}
      aria-controls="DataTables_Table_0"
      onChange={(v)=>{
        searchx(v.target.value)

      }}
    />
  </label>
</div>

      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                 
                  <th
                    className="sorting">
                    Name
                  </th>
                  
                  <th
                    className="sorting">
                    Username
                  </th>
                  <th
                    className="sorting">
                   Type
                  </th>

                 
                  {/* <th
                    className="sorting">
                    Create at
                  </th> */}
                
                  
                  

                </tr>
              </thead>
              <tbody>
      {
        agentList.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.name}</td>
                  <td>{v.username}</td>
                  <td>{v.type}</td>
                  {/* <td>{v.at}</td> */}
                  
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}