import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Nav from "./nav";
import Footer from "./footer";


export default function Dashboard() {
  const underlineRemove= {  textDecoration:'none'};

  const [all,setAll]=useState([])



  useEffect(() => {
    getAll()

    const access=JSON.parse(localStorage.getItem("Access"))
    if(access[0].a14!=true){
      window.location.replace("/admin_main")
    }
    
  }, []);


  const getAll = async (a)=> {
const data = new FormData();
    data.append('type',a);
        axios({
          method: 'post',
          url: '/api/user/state.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
              setAll(s.data)
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}


        {
            all.map((v)=>(
                <><div className="main-content">
                <h2> Statistics </h2>
              <div className="row">
    
                <div className="col-md-6 col-lg-3">
                <div class="card">
        <div class="card-header">
            <h4 class="card-title">All Users</h4>
        </div>
        <div class="card-body">
    
            <div className='row'>
                <div className='col-6'>
                    Enable
                </div>
                <div className='col-6'>
                {v.enable}
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    Disable
                </div>
                <div className='col-6'>
                    {v.disable}
                </div>
            </div>
    
            <div className='row'>
                <div className='col-6'>
                    Total
                </div>
                <div className='col-6'>
                {v.total}
                </div>
            </div>
    
        </div>
    </div>
                  
                </div>
                <div className="col-md-6 col-lg-3">
                <div class="card">
        <div class="card-header">
            <h4 class="card-title">All Admin</h4>
        </div>
        <div class="card-body">
    
            <div className='row'>
                <div className='col-6'>
                    Enable
                </div>
                <div className='col-6'>
                {v.admin_enable}
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    Disable
                </div>
                <div className='col-6'>
                    {v.admin_disable}
                </div>
            </div>
    
            <div className='row'>
                <div className='col-6'>
                    Total
                </div>
                <div className='col-6'>
                {v.admin_total}
                </div>
            </div>
    
        </div>
    </div>
                  
                </div>
                <div className="col-md-6 col-lg-6">

                </div>
                <div className="col-md-6 col-lg-3">
                <div class="card">
        <div class="card-header">
            <h4 class="card-title">All Manager</h4>
        </div>
        <div class="card-body">
    
            <div className='row'>
                <div className='col-6'>
                    Enable
                </div>
                <div className='col-6'>
                {v.manager_enable}
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    Disable
                </div>
                <div className='col-6'>
                    {v.manager_disable}
                </div>
            </div>
    
            <div className='row'>
                <div className='col-6'>
                    Total
                </div>
                <div className='col-6'>
                {v.manager_total}
                </div>
            </div>
    
        </div>
    </div>
                  
                </div>
                <div className="col-md-6 col-lg-3">
                <div class="card">
        <div class="card-header">
            <h4 class="card-title">All Agent</h4>
        </div>
        <div class="card-body">
    
            <div className='row'>
                <div className='col-6'>
                    Enable
                </div>
                <div className='col-6'>
                {v.agent_enable}
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    Disable
                </div>
                <div className='col-6'>
                    {v.agent_disable}
                </div>
            </div>
    
            <div className='row'>
                <div className='col-6'>
                    Total
                </div>
                <div className='col-6'>
                {v.agent_total}
                </div>
            </div>
    
        </div>
    </div>
                  
                </div>
    
              </div>
      
     
    
            
              
              
            </div></>
            ))
        }
        
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
  </>
  );
}