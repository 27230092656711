import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Nav from "./nav";
import Footer from "./footer";


export default function Dashboard() {
  const underlineRemove= {  textDecoration:'none'};

  const [teamList,setTeamList]=useState([])
  const [name,setName]=useState("")

  useEffect(() => {
    TeamList()
    const access=JSON.parse(localStorage.getItem("Access"))
    if(access[0].a14!=true){
      window.location.replace("/admin_main")
      
    }
  }, []);


  const TeamList = async ()=> {
const data = new FormData();
    data.append('status',"login");
        axios({
          method: 'post',
          url: '/api/user/pin_view.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            setTeamList(s.data)
            console.log(s.data)
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  const TeamNew = async ()=> {
const data = new FormData();
    data.append('name',name);
        axios({
          method: 'post',
          url: '/api/team/add.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            setName("");
            TeamList();
     
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
      
  const status_change = async (id,s)=> {
const data = new FormData();
    data.append('id',id);
    data.append('s',s);
        axios({
          method: 'post',
          url: '/api/team/delete.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            
            TeamList();
     
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          
  
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Last 50 PIN Log  </h5>
                  
                  </div>
                  <div className="m-t-10">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Status</th>
                            <th>Date</th>
                           
                           
                            
                          </tr>
                        </thead>
                        <tbody>

                            {
                                teamList.map(v=>(
                                    <tr>
                                        <td>{v.uid}</td>
                                        <td>{v.name}</td>
                                        <td>{v.s}</td>
                                        <td>{v.d}</td>
                                       
                                        

                                        </tr>

                                ))
                            }
                          
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
  </>
  );
}