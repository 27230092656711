import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Nav from "./nav";
import Footer from "./footer";


export default function Dashboard() {
  const underlineRemove= {  textDecoration:'none'};

  const [teamList,setTeamList]=useState([])
  const [stateD,setStateD]=useState([])
  const [name,setName]=useState("")
  const [msg,setMsg]=useState("")

  useEffect(() => {
    TeamList()
    state()

    const access=JSON.parse(localStorage.getItem("Access"))
    if(access[0].a13!=true){
      window.location.replace("/admin_main") 
    }

  }, []);


  const TeamList = async ()=> {
const data = new FormData();
    data.append('status',"login");
        axios({
          method: 'post',
          url: '/api/team/all_view.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            setTeamList(s.data)
            console.log("==>")
            console.log(s.data)
            console.log("==>")

            })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  const state = async ()=> {
const data = new FormData();
    data.append('status',"login");
        axios({
          method: 'post',
          url: '/api/team/state.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            setStateD(s.data)
            console.log(s.data)
            })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

const TeamNew = async ()=> {
  
const data = new FormData();
    data.append('name',name);
        axios({
          method: 'post',
          url: '/api/team/add.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            setName("");
            setMsg("")
            TeamList();
            state();
     
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
      
  const status_change = async (id,s)=> {
const data = new FormData();
    data.append('id',id);
    data.append('s',s);
        axios({
          method: 'post',
          url: '/api/team/delete.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            
            TeamList();
            state()
     
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
         
        
  
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>All Teams</h5>
                  
                  </div>
                  <div className="m-t-10">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                            {
                                teamList.map(v=>(
                                    <tr>
                                        <td>{v.id}</td>
                                        <td>
                                        
                                <div className="d-flex align-items-center">
                                
                                  <h6 className="m-l-10 m-b-0">
                                    {v.name}
                                  </h6>
                                </div>
                             
                                        </td>
                                        <td>{v.status=="1"?"Enable":"Disable"}</td>
                                        <td>
                                        {v.status=="1"? <button onClick={()=>{ status_change(v.id,"0")}} class="btn btn-danger m-r-5">Disable</button>:
                                        <button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-secondary m-r-5">Enable</button>}
                                            
                                            </td>
                                        </tr>

                                ))
                            }
                          
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-md-6">
            {
            stateD.map((v)=>(
              <div className="row">
                <h5>Team Statistics</h5>
              <div className="col-md-6 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="m-b-0">Total</p>
                        <h2 className="m-b-0">
                          <span>{v.total}</span>
                        </h2>
  
                      </div>
                  <div className="avatar avatar-icon avatar-lg avatar-red">
                        <i className="anticon anticon-profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="m-b-0">Enable</p>
                        <h2 className="m-b-0">
                          <span>{v.enable}</span>
                        </h2>
                      </div>
                      <div className="avatar avatar-icon avatar-lg avatar-cyan">
                        <i className="anticon anticon-bar-chart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="m-b-0">Disable</p>
                        <h2 className="m-b-0">
                          <span>{v.disable}</span>
                        </h2>
                      </div>
                      <div className="avatar avatar-icon avatar-lg avatar-red">
                        <i className="anticon anticon-profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
            ))
          }
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>New Team </h5>
                    
                  </div>
                  <div className="m-t-30">
                  <div>
              
     <div class="row" style={{marginBottom:"10px",padding:"20px"}}>
        <div class="col-12">
            <input type="text" value={name} onChange={(v)=>{ setName(v.target.value)}} class="form-control" placeholder="Team name"/>
        </div>
        <div class="col-12">
            {msg}
        </div>
        <div class="col-4" style={{marginTop:"20px"}}>
        <button onClick={()=>{
          if(name.length<4)
          {
            setMsg("Insert Name")

          }else{
            TeamNew()
          }
            
        }} class="btn btn-primary m-r-5"> <i class="anticon anticon-save"></i> Submit</button>
        </div>
    </div>
          </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
  </>
  );
}