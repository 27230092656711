import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav_manager";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  const getInq = async (id)=> {
    const data = new FormData();
    data.append('team_id',id);
            axios({
              method: 'post',
              url: '/api/manager/inq_view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
                getstate(id);
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getstate = async (team_id)=> {
    const data = new FormData();
        data.append("team_id",team_id)
            axios({
              method: 'post',
              url: '/api/manager/state.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setstate(s.data)
                console.log(s.data)
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const send_query = async (id)=> {
    const data = new FormData();
    data.append('id',id);
    data.append('s',"trash");
        
            axios({
              method: 'post',
              url: '/api/inq/move.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };




  const send_team_to_agent = async (inquiry_id,manager_id,agent_id)=> {

    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
    data.append('sender_id',manager_id);
    data.append('agent_id',agent_id);
        
            axios({
              method: 'post',
              url: '/api/manager/inq_send.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => { 
                console.log(s.data)
                getInq(JSON.parse(user)[0].team)
             
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const [list,setList]=useState([])
  const [state,setstate]=useState([])

 
  const [selected,setSelected]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [user,setUser]=useState(null);



  useEffect(() => {
    const a=localStorage.getItem("Manager");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      setUser(a)
      getInq(JSON.parse(a)[0].team);
      TeamList("Agent",JSON.parse(a)[0].team)


     
    }


  
  
  }, []);

  const TeamList = async (typ,id)=> {
    const data = new FormData();
        data.append('team_id',id);
        data.append('type',typ);
            axios({
              method: 'post',
              url: '/api/manager/team_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
    
                 if(typ=="Agent"){

                    setAgentList(s.data)
                }
    
           console.log(s.data)
    
    
    
                 
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };






  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Pending </p>
                      <h2 className="m-b-0">
                        <span>{state.length<1?"":<>{state[0].pending}</>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i class="anticon anticon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Last Week </p>
                      <h2 className="m-b-0">
                      <span>{state.length<1?"":<>{state[0].week}</>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-cyan">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Last Month </p>
                      <h2 className="m-b-0">
                      <span>{state.length<1?"":<>{state[0].month}</>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Team</p>
                      <h2 className="m-b-0">
                      <span>{state.length<1?"":<>{state[0].users}</>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-gold">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>Team Entries  <span onClick={()=>{getInq(JSON.parse(user)[0].team);}} ><i style={{color:"#FFC107"}}  className="anticon anticon-undo"></i> </span></h4>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th
                    className="sorting"
          
                  >
                    Inquiry
                  </th>
                  <th
                    className="sorting"
                   
          
                  >
                    Received
                  </th>
                  <th
                    className="sorting">
                    Status
                  </th>
                  <th
                    className="sorting">
                    Send by 
                  </th>
                  <th
                    className="sorting"
                
                  >
                   Action
                  </th>
                  
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.name}</td>
                  <td>{v.email}</td>
                  <td>{v.phone}</td>
                  <td>{v.adult==""?<></>:<b style={{color:"green"}}>{v.adult}</b>}{v.child==""?<></>:<b style={{color:"red"}}> {v.child}</b>}{v.infant==""?<></>:<b style={{color:"blue"}}> {v.infant}</b>}</td>
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>

      
           
           {/* <div className='row'>
            <div className='col-3'>IP</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.ip}</b></div>
           </div> */}

           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
           </div>
        
           <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
           </div>


           <div className='row'>
            
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
                
</center>
        

         
          </div>
        }
      >
        <Button>{v.inquiry_team_time}</Button>
      </HtmlTooltip>
      </td>
       <td>  
                 
                  <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
       value={selected}
        onChange={(event, newValue) => {
            console.log(newValue)
            setSelected(newValue)
        }}
      options={agentList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name} ({option.username}) 
        </Box>
      )}
      renderInput={(params) => <TextField {...params}  label="Name" />}
    />
     </td>
     <td>
        <button onClick={()=>{
            // inquiry_id,manager_id,agent_id
            console.log(JSON.parse(user)[0].id)
            console.log(selected.id)
                     send_team_to_agent(v.id,JSON.parse(user)[0].id,selected.id)
                    
                    
                  }} className="btn btn-warning m-r-5">Send</button>
                 
                
                  </td>
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}