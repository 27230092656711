import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const [list,setList]=useState([])
  const [listold,setListold]=useState([])
  const [listoldStatus,setListoldStatus]=useState(false)
  const [user,setUser]=useState()
  const [days,setDays]=useState("");
  const [d1,setD1]=useState(null);
  const [d2,setD2]=useState(null);

  const [selected,setSelected]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [teamList,setTeamList]=useState([])
  const [selected_t,setSelected_t]=useState(null)
  const [selected_type,setSelected_type]=useState(null)

  const [section_b,setSection_b]=useState(false)
  

  const [selected_website,setSelected_website]=useState(null)
  const [WebsiteList,setWebsiteList]=useState([])
  const [TypeList,setTypeList]=useState([])
  const [f1,setf1]=useState("")
  const [f2,setf2]=useState("")
  const [f3,setf3]=useState("")
  const [f4,setf4]=useState("")


  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      
      setUser(a)
      
      all_agent_get()
      TeamList()
      webList()
      Get_type_list()

      const access=JSON.parse(localStorage.getItem("Access"))
      if(access[0].a8!=true){
       window.location.replace("/admin_main")
      }else{
        setSection_b(access[0].a9)
      }

      
    }
  
  
  }, []);





  const all_agent_get = async (typ,id)=> {
    const data = new FormData();
   
            axios({
              method: 'post',
              url: '/api/admin/all_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                    setAgentList(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const Get_type_list = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api/web/listtype.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTypeList(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  const webList = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api/web/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setWebsiteList(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const getresponseCount = () => {
            return list.filter(item => item.manager_response == 0 ).length;
          }

          
  const getfilter_dail = () => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.manager_response >0)

      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.manager_response >0 );

      setList(newArray)

      console.log("array ",newArray)

    }  
  }
  const getfilter_none_dial = () => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.manager_response==0);

      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.manager_response==0);

      setList(newArray)

      console.log("array ",newArray)

    }  
  }

  const getInq = async ()=> {
    const data = new FormData();
    
    data.append('d1',d1);
    data.append('d2',d2);
            axios({
              method: 'post',
              url: '/api/admin/date_to_date.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  // Function to count the items based on status
  const getStatusCount = status => {
    return list.filter(item => item.agent_status === status).length;
  }

  const getfilter = status => {
    const a=list;
    setf1(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.agent_status === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.agent_status === status);
      setList(newArray)


      console.log("array ",newArray)

    }

  
    
  }
  const getfilter_Team = status => {
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.team_id === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.team_id === status);
      setList(newArray)
      console.log("array ",newArray)

    }

  
    
  }

  const getfilter_type = status => {
    const a=list;
    setf2(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.sender_type === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.sender_type === status);
      setList(newArray)
      console.log("array ",newArray)
    }
  }

  const getfilter_website = status => {
    const a=list;
    setf3(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.web === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.web === status);
      setList(newArray)
      console.log("array ",newArray)
    }
    
  }

  const getfilter_Agent = (status,name) => {
    setf4(name)
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.agent_id === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.agent_id === status);
      setList(newArray)
      console.log("array ",newArray)

    }

  
    
  }

  const getfilter_Type_source = (status) => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.type === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.type === status);
      setList(newArray)
      console.log("array ",newArray)

    }

  
    
  }

  const getTypeCount = status => {
    return list.filter(item => item.sender_type === status).length;
  }
  const TeamList = async ()=> {
    const data = new FormData();
        data.append('status',"login");
            axios({
              method: 'post',
              url: '/api/team/all_view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTeamList(s.data)
                })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const resetAll = () => {
    const a=listold;
    setList(a)
    setListoldStatus(false)
    setSelected()
    setSelected_website()
    setf1("")
    setf2("")
    setf3("")
    setf4("")
  }

  
  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          
        <div className='card' style={{padding:"20px"}} >
<h2>Inquirers</h2>
<div className='row'>

<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date Start</label>
<input type="date"  value={d1} onChange={(v)=>{ setD1(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>

<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date End</label>
<input type="date"  value={d2} onChange={(v)=>{ setD2(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>

<div className='row'>
<div className='col-3'>
<button onClick={()=>{

if(d1===null)
{
alert("Select Date")
}else if(d2===null)
{
alert("End Date")
}else{

  if(user=="")
  {

  }else{
    setList([])
    setSelected_website()
    setSelected()
    getInq()
    const access=JSON.parse(localStorage.getItem("Access"))
    if(access[0].a8!=true){
     window.location.replace("/admin_main")
    }
  }

}
}}  className="btn btn-primary m-r-5">Get</button>

</div>

</div>

</div>

</div>
        <h2 style={{color:"#054089"}}>Total Inquires : {list.length}</h2>
        <p>
          {f1!=""?<>{f1} / </>:<></>}
          {f2!=""?<>{f2} / </>:<></>}
          {f3!=""?<>{f3} / </>:<></>}
          {f4!=""?<>{f4} / </>:<></>}
        
        </p>
{
  listoldStatus?        <button style={{margin:"10px"}} onClick={()=>{
    resetAll()
  }}  class="btn btn-success m-r-5">Reset</button>:""
}

         <div className="row">

         <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Total Inquires</p>
                      <h2 className="m-b-0">
                      <span>{list.length}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter_dail()
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Manager Redial</p>
                      <h2 className="m-b-0">
                      <span>{list.length-getresponseCount()}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div onClick={()=>{
            getfilter_none_dial()
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Manager Pending Redial</p>
                      <h2 className="m-b-0">
                      <span>{getresponseCount()}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div onClick={()=>{
            getfilter("Reminder")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Reminder</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Reminder")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div onClick={()=>{
            getfilter("Bookout")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Bookout</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Bookout")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter("Bookin")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Bookin </p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Bookin")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter("Booked")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Booked</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Booked")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter("Busy")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Busy</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Busy")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter("Follow-up")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Follow-up</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Follow-up")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter("Not-Responsed")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Not-Responsed</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Not-Responsed")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter("Not-Interested")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Not-Interested</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Not-Interested")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter("Invalid")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Invalid</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Invalid")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter("Voicemail")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Voicemail</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Voicemail")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter_type("Admin")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Admin</p>
                      <h2 className="m-b-0">
                      <span>{getTypeCount("Admin")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div onClick={()=>{
            getfilter_type("Manager")
          }} className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Manager</p>
                      <h2 className="m-b-0">
                      <span>{getTypeCount("Manager")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
        
     
          
          
          
          </div>
        

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <div className='row'>
    
    <div className='col-3'>
<h6>By Type</h6>
</div>
    <div className='col-3'>
<h6>By Agent</h6>
     
      </div>
    <div className='col-3'>
<h6>By Web</h6>
     
      </div>
    <div className='col-3'>
<h6>By Team</h6>
     
      </div>
      
      </div>


   <div className='row'>

    <div className='col-3'>
    <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
       value={selected_type}
        onChange={(event, newValue) => {
            console.log(newValue)
            setSelected_type(newValue)  
            if(newValue===null)
            {
              resetAll()
              
            }else{
              getfilter_Type_source(newValue.name)
              

            }

        }}
      options={TypeList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name}
        </Box>
      )}
      renderInput={(params) => <TextField {...params}  label="Type.." />}
    />
       
    </div>
    <div className='col-3'>
    <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
       value={selected}
        onChange={(event, newValue) => {
            console.log(newValue)
            setSelected(newValue)  
            if(newValue===null)
            {
              resetAll()
              
            }else{
              getfilter_Agent(newValue.id,newValue.name)
              

            }

        }}
      options={agentList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name} 
        </Box>
      )}
      renderInput={(params) => <TextField {...params}  label="Agent Name" />}
    />
    </div>
    <div className='col-3'>
    <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
       value={selected_website}
        onChange={(event, newValue) => {
            console.log(newValue)
            setSelected_website(newValue)
            if(newValue===null)
            {
              resetAll()
              
            }else{
              getfilter_website(newValue.web)
              //getfilter_Agent

            }
            
        }}
      options={WebsiteList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.web}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.web}
        </Box>
      )}
      renderInput={(params) => <TextField {...params}  label="web" />}
    />
    </div>
    <div className='col-2' >
<Autocomplete
  disablePortal
  id="size-small-standard"
    size="small"
   value={selected_t}
    onChange={(event, newValue) => {
        console.log(newValue)
        setSelected_t(newValue)  
        if(newValue===null)
        {
          resetAll()
        }else{
          getfilter_Team(newValue.id)
          

        }

        

    }}
  options={teamList}
  // sx={{ width: 100 }}
  getOptionLabel={(option) => option.name}
  renderOption={(props, option) => (
    <Box component="li"  {...props}>
      
      {option.name}
    </Box>
  )}
  renderInput={(params) => <TextField {...params}  label="Select Team" />}
/>
</div>
   

   </div>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            {
              section_b&&<>
               <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
              <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th
                    className="sorting">
                    Email
                  </th>
                  <th
                    className="sorting">
                    Phone
                  </th>
                  <th className="sorting">
                    Agent Name
                  </th>
                  <th
                    className="sorting">
                    Status
                  </th>
                  <th
                    className="sorting">
                    Resp.
                  </th>
                  <th
                    className="sorting">
                   Action
                  </th>
                </tr>
              </thead>
              <tbody>
      {
        list.map((v,i)=>(   
        

              <>
                <tr role="row" className="odd">
              
                  <td>{v.id} </td>
                  <td style={{color:v.manager_response==0?"red":"green"}}>{v.web}</td>
                  <td>{v.email} </td>
                  <td>{v.phone} </td>
                  
                  
                  
                  <td>{v.agent_name}</td>
           
                  
                 
              
                
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>
  <div className='row'>
            <div className='col-3'>Name</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.name}</b></div>
  </div>
           <div className='row'>
            <div className='col-3'>Email</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.email}</b></div>
  </div>
           <div className='row'>
            <div className='col-3'>Phone</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.phone}</b></div>
  </div>
           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
  </div>
  <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
  </div>
  <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
  </div>
  <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
  </div>
  <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
  </div>
  <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
  </div>
        
  <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
  </div>
  <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
  </div>
  <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
  </div>
  <div className='row'>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
                
</center>
        

         
          </div>
        }
      >
        <Button>{v.agent_status}</Button>
      </HtmlTooltip>
      </td>
      <td>{v.response_num}</td>
      <td>
      <a target='__blank' href={"view_inquiry_admin?id="+v.id}> <button  className="btn btn-warning m-r-5">{v.type}</button></a>
      </td>
    
   
   
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
        </>
            }
           
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}