import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Nav from "./nav";
import Footer from "./footer";

export default function Dashboard() {
    
    const text_th = {
        color: 'Black',
        fontSize: '14px',
       
      };
      const text_td = {
        color: 'green',
        fontSize: '14px',
        fontWeight: 'bold',
        width: 'fit-content'
       
      };

  const [website,setWebsiteList]=useState([])
  const [type,setType]=useState([])
  const [d1,setd1]=useState([])


  useEffect(() => {
  //console.log("run")
  Get_type_list()
  webList()

  }, []);


  

  const save_website = async ()=> {
    const data = new FormData();
    data.append('web',d1);
            axios({
              method: 'post',
              url: '/api/web/add.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setd1("")
                webList()

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const Get_type_list = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api/web/listtype.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setType(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const webList = async ()=> {
            const data = new FormData();
             
                    axios({
                      method: 'post',
                      url: '/api/web/list.php',
                      data: data,
                      headers: {'Content-Type': 'multipart/form-data'},
                    })
                      .then(s => {
                        setWebsiteList(s.data)
        
                      })
                      .catch(s => {
                        console.log('here catch =>>', s);
                      });
                  };


  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
        <div className='card' style={{padding:"20px"}} >

<div className='row'>

<div className='col-3'>

<div class="form-group">
<label for="formGroupExampleInput">Website name</label>
<input type="text"  value={d1} onChange={(v)=>{ setd1(v.target.value)}} className="form-control" id="formGroupExampleInput" placeholder="Website"/>
</div>
</div>




<div className='row'>
<div className='col-3'>
<button onClick={()=>{


if(d1=="")
{
alert("Website name is null ?")
}else{
  save_website()
}

}}  className="btn btn-primary m-r-5">Save</button>

</div>


</div>

</div>

</div>


<div className='row'>
        
          
        <div className='col-4'>
        <div className="card">
        <h3 style={{padding:"20px"}}>Website Total: {
    website.length
 }
 </h3>

<div className="card-body">
<div>
  <div >
  
  
      
  {
        website.map((v,i)=>(   
    

        
            
       

        
           
            <div className='row'>
        
        <div className='col-1' style={text_td}>{i+1}</div>
        <div className='col-6' style={text_td}>{v.web}</div>
      
        </div>
    
        
        

      
   
       
       

  ))
  }
    
     
    
  </div>
</div>

</div>
  <div >


  
    
    
  </div>
  

</div>
        </div>
        <div className='col-4'>
        <div className="card">
        <h3 style={{padding:"20px"}}>Types Total: {
    type.length
 }
 </h3>

<div className="card-body">
<div>
  <div >
  
  
      
  {
        type.map((v,i)=>(   
    

        
            
       

        
           
            <div className='row'>
        
        <div className='col-1' style={text_td}>{i+1}</div>
        <div className='col-6' style={text_td}>{v.name}</div>
      
        </div>
    
        
        

      
   
       
       

  ))
  }
    
     
    
  </div>
</div>

</div>
  <div >


  
    
    
  </div>
  

</div>
        </div>
    </div>
        </div>
    
<Footer />
  
      </div>
  </>
  );
}