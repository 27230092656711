import * as React from 'react';

import { useState, useEffect } from "react";
import axios from 'axios';
import Nav from "./nav";
import Footer from "./footer";



export default function Dashboard() {

  const [folder_no,setFolderNo]=useState("")
  const [option_select,setOption_select]=useState("")
  const [options,setOptions]=useState([{name:"Court Case"},{name:"Negative Review"},{name:"Just Reviews"},{name:"Coming To Office Uk"},{name:"Others"}])
  
  const [agentList,setAgentList]=useState([])
  const [contact,setContact]=useState([])
  const [agent_id,setAgentID]=useState("")
  const [status,setStatus]=useState("open")
  const [remarks,setRemarks]=useState("")
  const [contact_id,setConatct_id]=useState("")
  const [type,setType]=useState("")

  const [create_by,setCreate_by]=useState("")
  const [allstatusHeading,setAll_statusHeading]=useState([])
  const [allstatusHeading_select,setAll_statusHeading_select]=useState([])
 
  useEffect(() => {

    const access=JSON.parse(localStorage.getItem("Access"))
    if(access[0].a24!=true){
      window.location.replace("/admin_main")
      const a=localStorage.getItem("Admin");

      //setCreate_by()
    }


    get()
    get_all_status()
  }, []);



  const allAgentList = async (typ)=> {
    const data = new FormData();
        data.append('type',typ);
            axios({
              method: 'post',
              url: '/api/user/user_list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
    
                 if(typ=="Agent"){

                    setAgentList(s.data)
                }
    
           console.log(s.data)
    
    
    
                 
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



  const get_contact_byid = async (id)=> {
const data = new FormData();
    data.append('id',id);
        axios({
          method: 'post',
          url: '/api/complaint/byidview_contact.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log(s.data)
            setContact(s.data)
            allAgentList("Agent")

        })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };


  const get_all_status = async (id)=> {
const data = new FormData();
    data.append('id',id);
        axios({
          method: 'post',
          url: '/api/complaint/get_all_status.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log(s.data)
            setAll_statusHeading(s.data)
            

        })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };




    const get =()=> {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    get_contact_byid(id)
    setConatct_id(id)
    
    };
    


    const UserNew = async ()=> {
    
    const a=localStorage.getItem("Admin");
    const z=JSON.parse(a)
    const uid=z[0].id
    if(a==null)
    {
      window.location.replace("/login")
    }

    const data = new FormData();
    data.append('uid',uid);
    data.append('type',type);
    data.append('agent_id',agent_id);
    data.append('status_id',allstatusHeading_select);
    data.append('remarks',remarks);
    data.append('contact_id',contact_id);

    data.append('option',option_select);
    data.append('folder',folder_no);


        axios({
          method: 'post',
          url: '/api/complaint/convert.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
          

            console.log('response =>>', s.data);

            if(s.data[0].msg=="already")
              {
                window.alert("Already Complaint")
              }else{
                window.location.replace("/convert_complaint_list");

              }




  
  
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
      



  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
<div className='card' style={{padding:"20px"}} >
    <h2>Contact to Complaint</h2>
<div className='row'>


<div className='col-4'>
  <h6>Web : </h6>
  <p>{contact.length<1?<></>:<>{contact[0].web}</>}</p>
</div>
{/* <div className='col-4'>
  <h6>ID : </h6>
  <p>{contact.length<1?<></>:<>{contact[0].id}</>}</p>
</div> */}
<div className='col-4'>
  <h6>Date : </h6>
  <p>{contact.length<1?<></>:<>{contact[0].date} </>}</p>
</div>
<div className='col-4'>
<h6>Time : </h6>
  <p>{contact.length<1?<></>:<>{contact[0].time}</>}</p>
</div>

<div className='col-4'>
<h6>Name : </h6>
  <p>{contact.length<1?<></>:<>{contact[0].name}</>}</p>
</div>

<div className='col-4'>
  <h6>Phone : </h6>
  <p>{contact.length<1?<></>:<>{contact[0].phone}</>}</p>
</div>

<div className='col-4'>
  <h6>Email : </h6>
  <p>{contact.length<1?<></>:<>{contact[0].email} </>}</p>
</div>
<div className='col-12'>
  <h6>Massage : </h6>
  <p>{contact.length<1?<></>:<>{contact[0].msg} </>}</p>
</div>






<div className='col-3'>
    <div class="form-group">
        <label for="formGroupExampleInput">Type</label>
        <select  value={type} onChange={(v)=>{ setType(v.target.value)}} className="form-select" aria-label="Default select example">
  <option value={""}>Select</option>
  <option>Client_Reply</option>
  <option>Convert_Complaint</option>
  


</select>
    </div>
    </div>
    {
  type=="Convert_Complaint"?<>
      <div className='col-3'>
    <div class="form-group">
        <label for="formGroupExampleInput">Select Agent</label>
        <select  value={agent_id} onChange={(v)=>{ setAgentID(v.target.value)}} className="form-select" aria-label="Default select example">
  <option value={""}>Select</option>

  {
    agentList.map((v)=>(
      <option value={v.id} >{v.name}</option>
    ))
  }
 

  

</select>
    </div>
  
    </div>

    {/* end */}
      <div className='col-3'>
    <div class="form-group">
        <label for="formGroupExampleInput">Select Option</label>
        <select  value={option_select} onChange={(v)=>{ setOption_select(v.target.value)}} className="form-select" aria-label="Default select example">
  <option value={""}>Select</option>

  {
    options.map((v)=>(
      <option>{v.name}</option>
    ))
  }
 

  

</select>
    </div>
  
    </div>
    {/* end */}
      <div className='col-3'>
    <div class="form-group">
        <label for="formGroupExampleInput">Folder No.</label>
        <input value={folder_no}  onChange={(v)=>{ setFolderNo(v.target.value)}} className="form-control" placeholder='Enter Folder No.'  />
    </div>
  
    </div>
    </>:<></>
}




<div className='col-10'>
    <div class="form-group">
        <label for="formGroupExampleInput">Heading Issue</label>
        <select  value={allstatusHeading_select} onChange={(v)=>{ setAll_statusHeading_select(v.target.value)}} className="form-select" aria-label="Default select example">
  <option value={""}>Select</option>
  {
    allstatusHeading.map((v)=>(
      <option>{v.status}</option>
    ))
  }

  


</select>
    </div>
    </div>




    <div className='col-10'>
    <div class="form-group">
        <label for="formGroupExampleInput">Remarks </label>
        <textarea type="text" class="form-control" onChange={(v)=>{ setRemarks(v.target.value)}} id="formGroupExampleInput"></textarea>
    </div>
    </div>




</div>

   
<div className='row'>
    <div className='col-6'>
    
      
  <button onClick={()=>{
    
   if(type =="Convert_Complaint")
    {
      if(agent_id=="" || option_select=="" || folder_no=="" || allstatusHeading_select=="" || remarks=="")
        {
          alert("ERROR “Please fill in all fields” ")
        }else{
          UserNew()
        }
    }else{
      if(allstatusHeading_select=="" || remarks=="")
        {
          alert("ERROR “Please fill in all fields” ")
        }else{
          UserNew()
        }
    }

 
      
    

}} className="btn btn-primary m-r-5">Submit</button>
  
    </div>

</div>



</div>


</div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
  </>
  );
}