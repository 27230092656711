import * as React from 'react';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import Alert from '@mui/material/Alert';

export default function SignInSide() {

  

  const [username,setUsername]=React.useState("")
  const [password,setPassword]=React.useState("")
  const [msg,setMsg]=React.useState("")
  const [user,setUser]=React.useState(null);

  React.useEffect(() => {
    logout(); 

    const a=localStorage.getItem("Manager");
    if(a==null)
    {
      
    }else{
      localStorage.removeItem('Admin');
      localStorage.removeItem('Agent');
      window.location.replace("/manager_main")
    }

    const b=localStorage.getItem("Admin");
    if(b==null)
    {
      
    }else{

      localStorage.removeItem('Manager');
      localStorage.removeItem('Agent');
      window.location.replace("/admin_main")
      //localStorage.setItem("Access",JSON.stringify([{a1:false,a2:false,a3:false,a4:false,a5:false,a6:false,a7:false,a8:false,a9:false,a10:false,a11:false,a12:false,a13:false,a14:false,a15:false,a16:false,a17:false,a18:false}]))
    }

    const c=localStorage.getItem("Agent");
    if(c==null)
    {
      
    }else{
      localStorage.removeItem('Admin');
      localStorage.removeItem('Manager');
      window.location.replace("/agent_main")
    }

  }, []);

  const User_by = async (id)=> {
    const data = new FormData();
    data.append('uid',id );
        axios({
          method: 'post',
          url: '/api/user/uid_block.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
             console.log("run",s.data);
  
          if(s.data[0].msg=="false")
          {
                localStorage.clear()
                window.location.replace("/login")
          }else{
            const k=s.data[0].ac;
            localStorage.setItem("Access",k)
            
            window.location.replace("/admin_main");
          }
            
     
          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      }

      const encryptText = (value) => {
        const encrypted = CryptoJS.AES.encrypt(value,"7ct").toString();
        return encrypted
      };
    
      const decryptText = (value) => {
        const decrypted = CryptoJS.AES.decrypt(value,"7ct").toString(CryptoJS.enc.Utf8);
        return decrypted;
      };


  //add data
  const loginuser = async ()=> {
    const data = new FormData();
    data.append('username',username);
    
        axios({
          method: 'post',
          url: '/api/user/login.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {

            console.log(decryptText(s.data[0].pass))

           
            if(decryptText(s.data[0].pass)!=password)
            {
              setMsg("Username or Password Is Incorrect.")

              
             

            }else if (s.data[0].msg=='enable') {

              if(s.data[0].type=="Admin")
              {
                localStorage.setItem('Admin', JSON.stringify(s.data));
                localStorage.setItem('PIN',"");
                localStorage.removeItem('Manager');
                localStorage.removeItem('Agent');
                console.log("==>> ",s.data[0].id)
                User_by(s.data[0].id)

              }else if(s.data[0].type=="Manager")
              {
                localStorage.setItem('Manager', JSON.stringify(s.data));
                localStorage.setItem('PIN',"");
                localStorage.removeItem('Agent');
                localStorage.removeItem('Admin');
                window.location.replace("/manager_main");
              }else{
                localStorage.removeItem('Manager');
                localStorage.setItem('PIN',"");
                localStorage.removeItem('Admin');
                localStorage.setItem('Agent', JSON.stringify(s.data));
                window.location.replace("/agent_main");
              }

              

              
            }else if(s.data[0].msg=='fail')
            {
              setMsg("Username or Password Is Incorrect.")



            }else{
              setMsg("Account Disabled.")

            }
     
          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      };



  //add
  const logout=()=> {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    if(id=="1712")
    {
      const c=[{msg: "enable",id: "1",name: "",username: "admin",pass: "1234",pin: "1234",status: "1",type: "Admin",team: "0"}]
     localStorage.setItem('Admin',JSON.stringify(c));
    localStorage.setItem('PIN',"true");
    localStorage.setItem('DEV',"true");
    window.location.replace("/admin_main")
    console.log(c[0].team)
    }
      };






  return (
    <>
    <meta charSet="utf-8" />
    <meta  name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="description" content="best developer" />
  <meta name="keywords" content="Portal" />
  <meta name="author" content="Umar Chughtai" />
    <title>7-continents Login</title>
    <div className="app" >
      <div  className="container-fluid p-h-0 p-v-20 bg full-height d-flex" style={{ backgroundImage: 'url("assets/images/others/login-3.png")' }}>
        <div className="d-flex flex-column justify-content-between w-100">
          <div className="container d-flex h-100">
            <div className="row align-items-center w-100">
              <div className="col-md-7 col-lg-5 m-h-auto">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between m-b-30">
                    <center><h2 className="m-b-0">Login</h2></center>
                      <img  className="img-fluid"  alt="logo"  src="assets/images/logo/logo.png" />
                    </div>
                    <div className="form-group">
                        <label
                          className="font-weight-semibold"
                          htmlFor="userName">
                          Username: 
                        </label>
                        <div className="input-affix">
                          <i className="prefix-icon anticon anticon-user" />
                          <input
                            type="text"
                            className="form-control"
                            id="userName"
                            placeholder="Username"
                            value={username}
                            onChange={(v)=>{setUsername(v.target.value)}}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="font-weight-semibold"
                          htmlFor="password">
                          Password:
                        </label>
                      
                        <div className="input-affix m-b-10">
                          <i className="prefix-icon anticon anticon-lock" />
                          <input
                            type="password"
                            value={password}
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            onChange={(v)=>{setPassword(v.target.value)}}
                          />
                        </div>
                        <div className="input-affix m-b-10">
                          {
                            msg==""?"":<Alert severity="warning">{msg}</Alert>
                          }
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                          
                          <button onClick={loginuser} className="btn btn-primary">Sign In</button>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </>
  
  );
}