import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function Dashboard() {
  
  const getInq = async (id)=> {
    const data = new FormData();
    data.append('agent_id',id);
    
            axios({
              method: 'post',
              url: '/api/agent/voicemail.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

                const se=s.data;
                setList(se)
                console.log(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

        

 

  const [list,setList]=useState([])
  const [state,setstate]=useState([])
  const [selected,setSelected]=useState(null)
  const [user,setUser]=useState(null);


  // Function to count the items based on status
  const getStatusCount = status => {
    return list.filter(item => item.agent_status === status).length;
  }

  useEffect(() => {
    const a=localStorage.getItem("Agent");
    if(a==null)
    {
      window.location.replace("/login")
    }else{

      setUser(a)
       getInq(JSON.parse(a)[0].id);
    }


  
  
  }, []);


  function getRemainingDays(targetDate) {
    const currentDate = new Date();
    const remainingTime = new Date(targetDate) - currentDate;
    
    // Convert remaining time from milliseconds to days
    const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
    
    return remainingDays;
}
  




  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          
          
    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>Voicemail Details </h4>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info">
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th
                    className="sorting"
          
                  >
                    Inquiry
                  </th>
                  <th
                    className="sorting"
                   
          
                  >
                    Received
                  </th>
                  <th
                    className="sorting">
                    Status
                  </th>
                 
                 
                  <th className="sorting">Account</th>
                  <th
                    className="sorting">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.date_portal} {v.date_time}</td>
                  <td>{v.sender_time}</td>
                  <td>{v.agent_status}</td>
                  
                    
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <>{v.url}</>
      
        }
      >
        <Button>{v.sender_type}</Button>
      </HtmlTooltip>
      </td>
      
     <td>
      <a target='black_' href={"/view_inquiry?id="+v.id}>
        <button 
        className="btn btn-warning m-r-5">View</button>
                 
                 </a>
                  </td>
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >

      </div>
      
    
    </div>





        </div>
<Footer />
</div>
      
  </>
  );
}