import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";
import Papa from 'papaparse';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const [list,setList]=useState([])
  const [listold,setListold]=useState([])
  const [listoldStatus,setListoldStatus]=useState(false)
  const [user,setUser]=useState()
  const [days,setDays]=useState("");
  const [d1,setD1]=useState(null);
  const [d2,setD2]=useState(null);

  const [selected,setSelected]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [report,setReport]=useState([])
  const [teamList,setTeamList]=useState([])
  const [selected_t,setSelected_t]=useState(null)
  const [selected_type,setSelected_type]=useState(null)
  

  const [selected_website,setSelected_website]=useState(null)
  const [WebsiteList,setWebsiteList]=useState([])
  const [TypeList,setTypeList]=useState([])
  const [f1,setf1]=useState("")
  const [f2,setf2]=useState("")
  const [f3,setf3]=useState("")
  const [f4,setf4]=useState("")


  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      
      setUser(a)

      TeamList()
      const access=JSON.parse(localStorage.getItem("Access"))
        if(access[0].a15!=true){
          window.location.replace("/admin_main")
          
        }

    }
  
  
  }, []);

  function decodeEntities(text) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }



  const all_agent_get = async (array)=> {
    const data = new FormData();
   
            axios({
              method: 'post',
              url: '/api/admin/all_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                    setAgentList(s.data)
                    getAllfilter(array,s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const Get_type_list = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api/web/listtype.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTypeList(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  const webList = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api/web/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setWebsiteList(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getInq = async ()=> {
    const data = new FormData();
    
    data.append('d1',d1);
    data.append('d2',d2);
            axios({
              method: 'post',
              url: '/api/admin/date_to_date.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                all_agent_get(s.data)
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  // Function to count the items based on status
  const getStatusCount = status => {
    return list.filter(item => item.agent_status === status).length;

}
  const getTeam_name = (id,array) => {

    let x= array.filter(item => item.id === id);
    return x[0].name;
  }
  const getStatusCount_Byagents = (status,array,id) => {

    var d=array.filter(item => item.agent_status === status && item.agent_id === id ).length;
  
    if(d==0)
    {
      return "0"

    }else{
      return d
  }
    }

  const getType_perAgent = (t,array,id) => {

    var d=array.filter(item => item.type ===t && item.agent_id === id ).length;
  
    if(d==0)
    {
      return "0"

    }else{
      return d
  }
    }

    

  const getStatusCount_Byagents_sender = (status,array,id) => {

    var d=array.filter(item => item.sender_type === status && item.agent_id === id ).length;
    if(d==0)
    {
      return "0"

    }else{
      return d
    }
   
  }
  const getInq_count = (id,array) => {
    return array.filter(item => item.agent_id === id).length;
  }




function sum_now(myArray) {

  let myObject = { id:"", name:"Total:", team:"",inq:myArray.reduce((total, current) => total + current.inq, 0)
            ,blast:myArray.reduce((total, current) => total + Number(current.blast), 0)
            ,fb:myArray.reduce((total, current) => total + Number(current.fb), 0)
        ,google:myArray.reduce((total, current) => total + Number(current.google), 0)
        ,chat:myArray.reduce((total, current) => total + Number(current.chat), 0)
        ,whatsapp:myArray.reduce((total, current) => total + Number(current.inq), 0)
        ,ref:myArray.reduce((total, current) => total + Number(current.whatsapp), 0)
        ,organic:myArray.reduce((total, current) => total + Number(current.organic), 0)
            ,reminder:myArray.reduce((total, current) => total + Number(current.reminder), 0)
            ,bookout:myArray.reduce((total, current) => total + Number(current.bookout), 0)
            ,bookin:myArray.reduce((total, current) => total + Number(current.bookin), 0)
            ,booked:myArray.reduce((total, current) => total + Number(current.booked), 0)
            ,busy:myArray.reduce((total, current) => total + Number(current.busy), 0)
            ,follow:myArray.reduce((total, current) => total + Number(current.follow), 0)
            ,responsed:myArray.reduce((total, current) => total + Number(current.responsed), 0)
            ,interested:myArray.reduce((total, current) => total + Number(current.interested), 0)
            ,invalid:myArray.reduce((total, current) => total + Number(current.invalid), 0)
            ,voicemail:myArray.reduce((total, current) => total + Number(current.voicemail), 0)
            ,admin:myArray.reduce((total, current) => total + Number(current.admin), 0)
            ,manager:myArray.reduce((total, current) => total + Number(current.manager), 0)
            
         }; 
        
        myArray.push(myObject)
        setReport(myArray)
  
 

}


// <tbody>
  const getAllfilter =(array,agents) => {

    // console.log("array ",array,"     agent",agents)

    let myArray = []; // initialize an empty array

    agents.map((v,io)=>{
        let c="";
        if(teamList.length<1)
        {
            c=v.team; 
        }else{
                c=getTeam_name(v.team,teamList)
            }
            console.log(myArray.length,"and ",io)
            
        let myObject = { id:v.id, name:v.name, team:c,inq:getInq_count(v.id,array)
            ,blast:getType_perAgent("blast",array,v.id)
            ,fb:getType_perAgent("Fb",array,v.id)
        ,google:getType_perAgent("google",array,v.id)
        ,chat:getType_perAgent("chat",array,v.id)
        ,whatsapp:getType_perAgent("whatsapp",array,v.id)
        ,ref:getType_perAgent("ref",array,v.id)
        ,organic:getType_perAgent("organic",array,v.id)
            ,reminder:getStatusCount_Byagents("Reminder",array,v.id)
            ,bookout:getStatusCount_Byagents("Bookout",array,v.id)
            ,bookin:getStatusCount_Byagents("Bookin",array,v.id)
            ,booked:getStatusCount_Byagents("Booked",array,v.id)
            ,busy:getStatusCount_Byagents("Busy",array,v.id)
            ,follow:getStatusCount_Byagents("Follow-up",array,v.id)
            ,responsed:getStatusCount_Byagents("Not-Responsed",array,v.id)
            ,interested:getStatusCount_Byagents("Not-Interested",array,v.id)
            ,invalid:getStatusCount_Byagents("Invalid",array,v.id)
            ,voicemail:getStatusCount_Byagents("Voicemail",array,v.id)
            ,admin:getStatusCount_Byagents_sender("Admin",array,v.id)
            ,manager:getStatusCount_Byagents_sender("Manager",array,v.id)
            
         }; 
        
        myArray.push(myObject)
    })


    sum_now(myArray)
    console.log("convert",myArray)
    

  }
  const getfilter = status => {
    const a=list;
    setf1(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.agent_status === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.agent_status === status);
      setList(newArray)


      console.log("array ",newArray)

    }

  
    
  }

  const getfilter_Type_source = (status) => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.type === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.type === status);
      setList(newArray)
      console.log("array ",newArray)

    }

  
    
  }

  const getTypeCount = status => {
    return list.filter(item => item.sender_type === status).length;
  }



  const TeamList = async ()=> {
    const data = new FormData();
        data.append('status',"login");
            axios({
              method: 'post',
              url: '/api/team/all_view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTeamList(s.data)
                })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const resetAll = () => {
    const a=listold;
    setList(a)
    setListoldStatus(false)
    setSelected()
    setSelected_website()
    setf1("")
    setf2("")
    setf3("")
    setf4("")
  }

  
  const downloadCSV = () => {
    const csv = Papa.unparse(report);
    var today = new Date()
    const csvDataBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvDataBlob);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', today+'.csv');
    tempLink.click();
  };
  
  return (
  <>

{/* <Nav /> */}



    <div  
    // className="page-container" 
    style={{backgroundColor:"#F9FBFD",padding:"30px"}}>
        {/* Content Wrapper START */}
        <div
        //  className="main-content"
        >
          
        <div className='card' style={{padding:"20px"}} >
<h2>Inquirers</h2>
<div className='row'>

  <div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date Start</label>
<input type="date"  value={d1} onChange={(v)=>{ setD1(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>
<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date End</label>
<input type="date"  value={d2} onChange={(v)=>{ setD2(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>


<div className='row'>
<div className='col-3'>
<button onClick={()=>{

if(d1===null)
{
alert("Select Date")
}else if(d2===null)
{
alert("End Date")
}else{

  if(user=="")
  {

  }else{
    setList([])
    setSelected_website()
    setSelected()
    getInq()
  }

}
}}  className="btn btn-primary m-r-5">Get</button>

</div>

</div>

</div>

</div>
       <h2 style={{color:"#054089"}}>Total Inquires : {list.length}</h2>
        
{
  listoldStatus?        <button style={{margin:"10px"}} onClick={()=>{
    resetAll()
  }}  class="btn btn-success m-r-5">Reset</button>:""
}

         <div className="row">

          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Reminder</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Reminder")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Bookout</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Bookout")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Bookin </p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Bookin")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Booked</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Booked")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Busy</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Busy")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Follow-up</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Follow-up")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Not-Responsed</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Not-Responsed")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Not-Interested</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Not-Interested")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Invalid</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Invalid")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Voicemail</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Voicemail")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Admin</p>
                      <h2 className="m-b-0">
                      <span>{getTypeCount("Admin")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div  className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Manager</p>
                      <h2 className="m-b-0">
                      <span>{getTypeCount("Manager")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    <div className="card" style={{width:"100%"}} >
    {
                    report.length<1?<></>:<><div style={{padding:"10px"}}>
                    <button  className="btn btn-primary m-r-5" onClick={downloadCSV}>Export CSV</button>
                  </div> </>
                }
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
             
              <tbody>
               
       {
        
        report.map((v,i)=>(   
        

              <>
                <tr key={i} role="row" className="odd">
                  {/* <td>{v.id} </td> */}
                  <td>{v.name}</td>
                  {/* <td>{v.team}</td>                   */}
                  <td>{v.inq}</td>
                  <td>{v.blast}</td>
                  <td>{v.fb}</td>
                  <td>{v.google}</td>
                  {/* <td>{v.chat}</td>
                  <td>{v.whatsapp}</td> */}
                  {/* <td>{v.ref}</td> */}
                  <td>{v.organic}</td>
                  <td>{v.reminder}</td>
                  <td>{v.follow}</td>
                  <td>{v.voicemail}</td>
                  <td>{v.busy}</td>
                  <td>{v.responsed}</td>
                  <td>{v.booked}</td>
                  {/* <td>{v.bookout}</td>
                  <td>{v.bookin}</td> */}
                  <td>{v.interested}</td>
                 
                  {/* <td>{v.invalid}</td> */}
                  {/* <td>{v.admin}</td>
                  <td>{v.manager}</td> */}
                  </tr>
                </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div >
      </div>
    </div>

        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}