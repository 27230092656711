import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Nav from "./nav_manager";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  
  const getInq = async (id)=> {
    const data = new FormData();
    data.append('id',id);
    

            axios({
              method: 'post',
              url: '/api/complaint/show_complaint_byid.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                setList(s.data)

                const c=s.data;

                if(c.length>0)
                {
                  getDetails(c[0].id)
                }
                
                

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getDetails = async (cid)=> {
    const data = new FormData();
    data.append('cid',cid);
    

            axios({
              method: 'post',
              url: '/api/complaint/view_details_complaint_byid.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                setDetails(s.data)
                console.log("Details=>complaint ",s.data)
                

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


          const access_checker=()=>{
            const b=JSON.parse(localStorage.getItem("Access"))
            if(b[0].a2==false)
            {
              window.location.replace("/view_pending_admin")
            }
          }

  const [list,setList]=useState([])
  const [msg,setMsg]=useState("")

  const [details,setDetails]=useState([])
  const [user,setUser]=useState(null);
  const [uid,setUserID]=useState("");
  const [cid,setCID]=useState("");

  useEffect(() => {

    const a=localStorage.getItem("Manager");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
     // setUser(a)
     
      setUserID(JSON.parse(a)[0].id)


     
    }
    
      const queryParams = new URLSearchParams(window.location.search)
      const id = queryParams.get("id")
      setCID(id)
      getInq(id);
  
  }, []);

  const save = async (cid,uid,msg)=> { 
    setMsg("")
    const data = new FormData();
        data.append('cid',cid);
        data.append('uid',uid);
        data.append('msg',msg);
       
            axios({
              method: 'post',
              url: '/api/complaint/add_details_complaint_byid.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setMsg("")
                getDetails(cid)
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };








  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
      
          
        


    <div >
      
   <div style={{paddingTop:"10px",paddingLeft:"10px"}}>
   <h4>Complaint</h4>
   </div>
   <div>
 <div>
 


      <div id="data-table_wrapper" className="dataTables_wrapper ">
        {
          list.map((v)=>(

            <>
            <div className='card' >
             
             <div className="row" style={{margin:"10px"}}>
           



             {
              v.type=="Convert_Complaint"?<>
                 {/* Start */}
            <div className="col-3">
                <h6>Web</h6>
                <p>{v.web}</p>
                </div>
            <div className="col-3">
                <h6>Contact Date</h6>
                <p>{v.date}</p>
                </div>
            <div className="col-3">
                <h6>Contact Time</h6>
                <p>{v.time}</p>
                </div>
            <div className="col-3">
                <h6>Contact Id</h6>
                <p>{v.contact_id}</p>
                </div>
            <div className="col-3">
                <h6>Name</h6>
                <p>{v.name}</p>
                </div>
            <div className="col-3">
                <h6>Email</h6>
                <p>{v.email}</p>
                </div>
            <div className="col-3">
                <h6>Phone</h6>
                <p>{v.phone}</p>
                </div>

            <div className="col-12">
                <h6>Massage</h6>
                <p>{v.msg}</p>
                </div>
          {/* end */}
          </>:<></>
             }
          
               
 
               <div className="col-2">
                 <h6>Type</h6>
                 <p>{v.type}</p>
                 </div>
                 
               <div className="col-2">
                 <h6>Folder</h6>
                 <p>{v.folder}</p>
                 </div>
               <div className="col-2">
                 <h6>Option</h6>
                 <p>{v.option}</p>
                 </div>
               <div className="col-3">
                 <h6>Agent Name</h6>
                 <p>{v.agent_name}</p>
                 </div>
 
               <div className="col-3">
                 <h6>Create At</h6>
                 <p>{v.create_at}</p>
                 </div>
               <div className="col-12">
                
                 <h3>{v.heading}</h3>
                 <p>
                  {
                   v.remarks
                  } 
                 </p>
                 </div>


               <div className="col-12 ">
                
              {
                details.map((v)=>(
                  <p style={{margin:"10px",padding:"10px"}} className="card">
                  <span style={{color:"blue"}}>{v.name}</span>
                  {v.msg}
                  </p>
                ))
              }
                 
                 </div>
                 
                 </div>

                 <div style={{margin:"15px"}}>
                 
      
        <div className="form-row">
            <div className="col-md-12 mb-3">
                <label for="input1">Massage</label>
                <textarea type="text" className="form-control"  onChange={(v)=>{ setMsg(v.target.value)}} id="formGroupExampleInput" value={msg}></textarea>
            </div>



        </div>
        <div className="form-row">
            <div className="col-md-12 ">
                <button type="submit" onClick={()=>{
                  if(msg!=""){save(v.id,uid,msg) }
                      
                }} className="btn btn-primary">Submit</button>
            </div>
        </div>
  
                 </div>
             
             </div>
             </>
          ))
        }
      
        
        
      </div>
    </div>
  
  </div>
      <div >

      </div>
      
    </div>
        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}