import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Nav from "./nav";
import Footer from "./footer";


export default function Dashboard() {
  const underlineRemove= {  textDecoration:'none'};

  const [adminList,setAdminList]=useState([])
  const [managerList,setManagerList]=useState([])
  const [agentList,setAgentList]=useState([])
  const [accesss,setAccess]=useState([])
  const [userList,setUserList]=useState([])
  const [name,setName]=useState("")

  useEffect(() => {
    TeamList("all")
    TeamList("Agent")
    TeamList("Admin")
    TeamList("Manager")
    const access=JSON.parse(localStorage.getItem("Access"))
    setAccess(access)
    if(access[0].a11!=true){
      window.location.replace("/admin_main")
      
    }

  }, []);


  const TeamList = async (a)=> {
const data = new FormData();
    data.append('type',a);
        axios({
          method: 'post',
          url: '/api/user/user_list.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            

            if(a=="Admin")
            {
                setAdminList(s.data)
                console.log("==> ",s.data)
            }else if(a=="Manager")
            {
                setManagerList(s.data)
            }else if(a=="Agent"){
                setAgentList(s.data)
            }else{
                setUserList(s.data)
            }

       



             
     
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  const TeamNew = async ()=> {
const data = new FormData();
    data.append('name',name);
        axios({
          method: 'post',
          url: '/api/team/add.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            setName("");
            agentList();
     
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
      
  const status_change = async (id,s)=> {
const data = new FormData();
    data.append('id',id);
    data.append('s',s);
        axios({
          method: 'post',
          url: '/api/user/delete.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            
            TeamList("all")
            TeamList("Agent")
            TeamList("Admin")
            TeamList("Manager")
            
     
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          {/* <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Total Teams</p>
                      <h2 className="m-b-0">
                        <span>3</span>
                      </h2>

                    </div>
                <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-profile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Managers</p>
                      <h2 className="m-b-0">
                        <span>3</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-cyan">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Agents</p>
                      <h2 className="m-b-0">
                        <span>3057</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-profile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Total Members</p>
                      <h2 className="m-b-0">
                        <span>4</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-gold">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
  
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">All Users</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Agent</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Managers</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="pills-contact-tab-a" data-toggle="pill" href="#pills-contact-a" role="tab" aria-controls="pills-contact-a" aria-selected="false">Admin</a>
    </li>
</ul>
<div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
    <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>All Users</h5>
                  </div>
                  <div className="m-t-10">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Extension</th>
                        
                            <th>Pin</th>
                           
                            <th>Type</th>
                            <th>Team</th>
                      
                            
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                            {
                                userList.map(v=>(
                                    <tr>
                                        <td>{v.id}</td>
                                        <td>
                                        
                                <div className="d-flex align-items-center">
                                
                                  <h6 className="m-l-10 m-b-0">
                                    {v.name}
                                  </h6>
                                </div>
                             
                                        </td>
                                        <td>{v.username}</td>
                                        <td>{v.ex}</td>
                                        
                                        <td>{v.pin}</td>
                                        
                                       
                                            <td>{v.type}</td>
                                            <td>{v.team}</td>
                                            
                                           
                                        <td>
                                        {v.status=="1"? <button onClick={()=>{ status_change(v.id,"0")}} class="btn btn-danger m-r-5">Disable</button>:
                                        <button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-secondary m-r-5">Enable</button>}

                                        {
                                           accesss[0].a24==true?<><a href={"/edit_user?id="+v.id} ><button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-warning m-r-5">Edit</button></a></>:<></>
                                            
                                            
                                          

                                        }
                                            
                                           
                                            
                                        
                                            
                                            </td>
                                        </tr>

                                ))
                            }
                          
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
    </div>
    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
    <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>All Agent</h5>
                  </div>
                  <div className="m-t-10">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Extension</th>
                           
                            <th>Pin</th>
                           
                       
                            <th>Team</th>
                            
                            <th>By</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                            {
                                agentList.map(v=>(
                                    <tr>
                                        <td>{v.id}</td>
                                        <td>
                                        
                                <div className="d-flex align-items-center">
                                  <h6 className="m-l-10 m-b-0">{v.name}</h6>
                                </div>
                             
                                        </td>
                                        <td><p style={{cursor:"pointer"}} data-toggle="tooltip" data-placement="top" title={v.at}>
                                        {v.username}
                                        </p></td>
                                        <td>{v.ex}</td>
                                        
                                        <td>{v.pin}</td>
                                        
                                       
                                           
                                            <td>{v.team}</td>
                                           
                                            <td>{v.by}</td>
                                            <td>{v.status=="1"?"Enable":"Disable"}</td>
                                        <td>
                                        {v.status=="1"? <button onClick={()=>{ status_change(v.id,"0")}} class="btn btn-danger m-r-5">Disable</button>:
                                        <button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-secondary m-r-5">Enable</button>}
                                                {
                                           accesss[0].a24==true?<><a href={"/edit_user?id="+v.id} ><button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-warning m-r-5">Edit</button></a></>:<></>
                                            
                                            
                                          

                                        }
                                            </td>
                                        </tr>

                                ))
                            }
                          
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
    </div>
    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
    <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>All Manager</h5>
                  </div>
                  <div className="m-t-10">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Extension</th>
                            
                            <th>Pin</th>
                           
                           
                            <th>Team</th>
                            
                            
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                            {
                                managerList.map(v=>(
                                    <tr>
                                        <td>{v.id}</td>
                                        <td>
                                        
                                <div className="d-flex align-items-center">
                                
                                  <h6 className="m-l-10 m-b-0">
                                    {v.name}
                                  </h6>
                                </div>
                             
                                        </td>
                                        <td><p style={{cursor:"pointer"}} data-toggle="tooltip" data-placement="top" title={v.at}>
                                        {v.username}
                                        </p></td>
                                      
                                        <td>{v.ex}</td>
                                        <td>{v.pin}</td>
                                        
                                            <td>{v.team}</td>
                                          
                                           
                                            <td>{v.status=="1"?"Enable":"Disable"}</td>
                                        <td>
                                        {v.status=="1"? <button onClick={()=>{ status_change(v.id,"0")}} class="btn btn-danger m-r-5">Disable</button>:
                                        <button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-secondary m-r-5">Enable</button>}
                                                  {
                                           accesss[0].a24==true?<><a href={"/edit_user?id="+v.id} ><button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-warning m-r-5">Edit</button></a></>:<></>
                                            
                                            
                                          

                                        }
                                            </td>
                                        </tr>

                                ))
                            }
                          
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
    </div>
    <div class="tab-pane fade" id="pills-contact-a" role="tabpanel" aria-labelledby="pills-contact-tab-a">
    <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>All Admin</h5>
                  </div>
                  <div className="m-t-10">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Extension</th>
                            <th>Pin</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                            {
                                adminList.map(v=>(
                                    <tr>
                                        <td>{v.id}</td>
                                        <td>
                                        
                                <div className="d-flex align-items-center">
                                
                                  <h6 className="m-l-10 m-b-0">
                                    {v.name}
                                  </h6>
                                </div>
                             
                                        </td>
                                        <td >
                                          <p style={{cursor:"pointer"}} data-toggle="tooltip" data-placement="top" title={v.at}>
                                        {v.username}
                                        </p>
                                        </td>
                                        <td>{v.ex}</td>
                                        <td>{v.pin}</td>
                                            
                                            
                                            <td>{v.status=="1"?"Enable":"Disable"}</td>
                                        <td>
                                        {v.status=="1"? <button onClick={()=>{ status_change(v.id,"0")}} class="btn btn-danger m-r-5">Disable</button>:
                                        <button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-secondary m-r-5">Enable</button>}
                                                   {
                                           accesss[0].a24==true?<><a href={"/edit_user?id="+v.id} ><button onClick={()=>{ status_change(v.id,"1")}} class="btn btn-warning m-r-5">Edit</button></a></>:<></>
                                            
                                            
                                          

                                        }
                                            </td>
                                        </tr>

                                ))
                            }
                          
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
    </div>
</div>
        
          
          
        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
  </>
  );
}